// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-funkciok-js": () => import("./../../../src/pages/funkciok.js" /* webpackChunkName: "component---src-pages-funkciok-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integraciok-js": () => import("./../../../src/pages/integraciok.js" /* webpackChunkName: "component---src-pages-integraciok-js" */),
  "component---src-pages-kalkulator-js": () => import("./../../../src/pages/kalkulator.js" /* webpackChunkName: "component---src-pages-kalkulator-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-templates-blogpost-js": () => import("./../../../src/templates/blogpost.js" /* webpackChunkName: "component---src-templates-blogpost-js" */)
}

